<template>
  <el-card>
    <el-alert type="warning" show-icon title="提示：当前设置更改后，需点击顶部右上角 → “保存发布”，保存后才可生效。"></el-alert>
    <br />
    <template #header>全局设置</template>
    <div class="title">基础设置</div>
    <div class="list">
      <span>选择配色方案</span>
      <div class="group">
        <div class="item" v-for="item in content" :key="item.color" @click="tabactive(item.color)" :style="'background:' + item.color">
          <template>{{ item.label }}</template>
          <i class="el-icon-check active" v-if="active === item.color"></i>
        </div>
      </div>
    </div>

    <div class="title">自定义DIY样式</div>
    <el-form :model="ruleForm" class="ruleForm" label-width="140px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="系统主色">
            <el-input readonly>
              <template #prefix>
                <el-color-picker></el-color-picker>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <span class="describe">包含顶部底色，底部TAB上线的颜色</span>
        </el-col>
      </el-row>
      <el-form-item label="系统头底文字色">
        <el-radio-group>
          <el-radio label="white">白色</el-radio>
          <el-radio label="black">黑色</el-radio>
        </el-radio-group>
        <span class="describe">顶部的文字颜色，建议与背景色相反</span>
      </el-form-item>
      <el-row>
        <el-col :span="6">
          <el-form-item label="系统辅色">
            <el-input readonly>
              <template #prefix>
                <el-color-picker></el-color-picker>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="系统主色渐变色">
            <el-input readonly>
              <template #prefix>
                <el-color-picker></el-color-picker>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <span class="describe">建议与系统主色颜色相近</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="系统文字主色">
            <el-input readonly>
              <template #prefix>
                <el-color-picker></el-color-picker>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="系统文字辅色">
            <el-input readonly>
              <template #prefix>
                <el-color-picker></el-color-picker>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      content: [
        {
          label: '梦幻蓝',
          color: '#4a93ee'
        },
        {
          label: '经典红',
          color: '#fc464b'
        },
        {
          label: '气质金',
          color: '#c2a66c'
        },
        {
          label: '清新绿',
          color: '#66bc76'
        },
        {
          label: '活力黄',
          color: '#fbc52e'
        },
        {
          label: '钢琴黑',
          color: '#333333'
        },
        {
          label: '俏丽粉',
          color: '#fd577e'
        },
        {
          label: '优雅紫',
          color: '#6775e1'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  background-color: rgb(249, 249, 249);
  padding: 10px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 50px 0;
  span {
    margin-right: 20px;
    height: 100px;
    line-height: 100px;
  }
  .group {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 100px;
      height: 100px;
      margin: 0 30px 20px 0;
      font-size: 16px;
      font-family: 'Microsoft Yahei';
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #daebf2;
      position: relative;
      color: #fff;
      cursor: pointer;
      .active {
        position: absolute;
        bottom: 15%;
        right: 41%;
        font-weight: bold;
        color: #fff;
        text-shadow: 0px 3px 1px #0c0d0e;
      }
    }
  }
}
.ruleForm {
  margin-top: 20px;
  ::v-deep(.el-input__inner) {
    padding-left: 50px;
  }
  .describe {
    color: #999999;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
  }
}
</style>
